import React from "react"
import HomePage from "../components/Homepage";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SEO from '../components/seo'


export default function Index() {
    return (
        <div className="indexPage">
            <SEO title="Digital Product Agency | UI/UX + Tech | 1st Main"/>
            <div className="nav-border"></div>
            <div id="page-wrap">
                <div className="margin-container">
                    <HomePage/>
                </div>
            </div>
         
        </div>
    )
}

