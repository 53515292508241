import React, {useState, useEffect} from "react"
import styled from 'styled-components'
import {graphql, useStaticQuery} from 'gatsby'
import Quote from './Quote'
import Info from './Info'
import Fade from 'react-reveal/Fade';
import Menu from './Menu';
import Footer from './Footer'
import Link from "./Link";
import ScrollingColorBackground from 'react-scrolling-color-background';
import SEO from "./seo";

const Homepage = () => {

    const data = useStaticQuery(graphql`
    query MyQuery {
        allStrapiHomepages(sort: {fields: published_at, order: DESC}) {
          edges {
            node {
              id
              content
              title
              subheading
              background
              textColour
              link
              image {
                publicURL
              }
            }
          }
        }
        allStrapiQuotes {
          edges {
            node {
                background
              }
          }
        }
        allStrapiProducts {
          edges {
            node {
              background
            }
          }
        }
        strapiHomeFirstmain {
          logo {
            publicURL
          }
          content
          title
        }
      }
    `)

    const {strapiHomeFirstmain: firstMain} = data;

    return (
        <StyledWrapper>
            <SEO path="/" />
            <Menu/>
            <ScrollingColorBackground
                selector=".js-color-stop[data-background-color]"
                colorDataAttribute="data-background-color"
                initialRgb="rgb(255, 255, 255)"
                className="testSection"
                style={{left: '0px', top: '0px', bottom: '0px', right: '0px', position: 'fixed'}}
            />

            <div className="homepage">
                <Fade top duration={1300}>
                    <section className="company mb-5 js-color-stop" data-background-color="rgb(255,255,255)">
                        <img src={firstMain.logo.publicURL} alt=""/>
                        <h1 className="company-byline">{firstMain.title}</h1>
                        <p className="company-desc info__desc mt-3">{firstMain.content}</p>
                    </section>
                </Fade>


                <div id="projects" className="project-list">
                    {data.allStrapiHomepages.edges.map(doc => (
                        <section key={doc.node.id} className="block container js-color-stop"
                                 data-background-color={doc.node.background}>
                            <Link className="project-link" to={doc.node.link} >
                                <div className="block__image">
                                    <img src={doc.node.image.publicURL} className="image">
                                    </img>
                                </div>
                                <div className="block__main d-flex align-self-start flex-lg-row"
                                     style={{color: doc.node.textColour}}>
                                    <h2 className="block__title">{doc.node.content}</h2>
                                    <div className="block__desc mr-auto mr-lg-0 ml-lg-auto">
                                        <h4>{doc.node.title}</h4>
                                        <p className="d-none">{doc.node.subheading}</p>
                                    </div>
                                </div>
                            </Link>
                        </section>
                    ))}
                </div>

                <section className="js-color-stop quote-info-section" data-background-color="rgb(255,255,255)">
                    <Quote/>
                    <Info/>
                </section>
            </div>
            <Footer/>
        </StyledWrapper>
    )
}
export default Homepage;


const StyledWrapper = styled.div`

  .project-list {
    position: relative;
  }

  .homepage {
    border-left: none;
    overflow: hidden;
  }

  .company-byline {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 30px;
    text-transform: uppercase;
    color: #c15f6d;
  }

  .company-desc {
    text-align: center;
    max-width: 600px;
  }

  .company > a, .company > a:hover, .company > a:visited, .company > a::before {
    text-decoration: none;
    border-bottom: none;
  }

  .company > a::before {
    visibility: hidden;
  }

  .project-link {
    display: block;
    width: 100%;
  }

  .project-link:before {
    visibility: hidden;
  }

  .block {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 50px 0px 250px;
    overflow: hidden;
  }

  .company {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 150px 30px 75px;
    max-width: 450px;
  }

  .block__image {
    transition: transform .75s, filter .75s ease-in-out !important;
    width: 100%;
  }

  .block__title {
    line-height: 24px;
  }

  .block__desc > h4, .block__desc > p {
    cursor: pointer;
  }

  .block__image:hover {
    transform: scale(1.02);
    cursor: pointer;
  }

  .block__main > h2 {
    max-width: 850px;
    padding-right: 10px;
    position: relative;
    cursor: pointer;
  }

  .block__main {
    width: 100%;
    padding-top: 28px;
    margin: 0;
    display: flex;
    justify-content: space-between;
  }

  .block__marg > h1, .block__marg > .block__desc > h4, .block__marg > .block__desc > p {
    color: white;
  }

  .company > h1 {
    text-align: center;
  }

  .block__main, .block__marg {
    padding: 0px;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
  }

  .block__main > h2, .block__marg > h2 {
    width: 100%;
  }

  .block {
    padding: 10px 30px 144px;
  }


  .block__desc > h4 {
    padding-top: 10px;
  }

  .block__image {
    //height: 300px;
  }

  .image {
    height: 300px;
    object-fit: cover;
    position: relative;
    width: 100%;
  }
  
  .quote-info-section {
    min-height: 80vh;
  }

  @media (min-width: 992px) {

    .company {
      max-width: 800px;
      padding: 200px 20px 100px;
    }


    .homepage {
      left: 60px;
      height: 100%;
      transition: 750ms;
    }

    .block__title {
      line-height: 37px;
      padding-top: 10px;
    }


    .image {
      height: 600px;
      width: 1200px;
      max-width: 100%;
    }

  }

  @media (max-width: 992px) {

  }
`