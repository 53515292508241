import React from 'react'
import styled from 'styled-components';


const Info = () => {
    return (
        <StyledWrapper>
            <div className="info">
                <div className="info__main container">
                    <div className="row text-center text-md-left justify-content-center">
                        <div className="col-md-6 col-12">
                            <h3 className="d-sm-block d-none">We design digital experiences</h3>
                            <h3 className="d-sm-none d-block">Digital Product Agency</h3>
                            <p className="info__desc">We learn about our clients’ business, sector and challenges to
                                develop robust strategy and designs that leave meaningful and lasting impressions on
                                customers</p>
                            <div className="link d-flex justify-content-center justify-content-lg-start">
                                <a href="/about">ABOUT US</a>
                                <a href="/contact">GET IN TOUCH</a>
                            </div>
                        </div>
                        <div className="col-md-4 col-12 justify-content-center d-flex">
                            <ul className="expertise-list">
                                <li>Websites</li>
                                <li>E-Commerce</li>
                                <li>Mobile Apps</li>
                                <li>Web Platforms</li>
                                <li>Interactive Experiences</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </StyledWrapper>
    )
}

export default Info

const StyledWrapper = styled.div`

  .expertise-list {
    margin-top: 30px;
  }

  .expertise-list > li {
    font-size: 18px;
    color: #5E574A;
    margin-bottom: 10px;
  }

  .info {
    background: #ffffff;
    transition: 750ms;
    height: 100%;
  }

  .info__main {
    padding: 150px 20px;
  }


  ul {
    padding-left: 0px;
  }

  .link > a {
    display: inline-block;
    margin: 15px;
  }


  @media (min-width: 992px) {

    .link > a {
      margin: 15px 25px 15px 0;
    }

    .expertise-list {
      margin-top: 0;
    }

    .expertise-list > li {
      font-size: 24px;
      color: #5E574A;
      margin-bottom: 10px;
    }
  }
`